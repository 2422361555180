<template>
  <v-menu
    v-model="isMenuOpen"
    :close-on-content-click="false"
    offset-y
    nudge-left="350"
    max-width="370"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn text fab small color="transparent" v-bind="attrs" v-on="on">
        <default-fab
          color="primary"
          tooltip-text="Mais Filtros"
          :top="false"
          :left="true"
        >
          <v-icon>mdi-filter-variant</v-icon>
        </default-fab></v-btn
      >
    </template>

    <v-card class="d-flex align-items-center" @keyup.enter="filter" data-vuetify>
      <v-list class="py-5" color="background">
        <v-row justify="center" dense>
          <v-list-item>
            <default-text-field
              label="Pedido"
              background-color="background"
              v-model="filterParams.numeroPedido"
              prepend-inner-icon="mdi-package-variant-closed"
            />
          </v-list-item>
          <v-list-item>
            <default-text-field
              v-model="filterParams.transportadoraNome"
              label="Transportadora"
              background-color="background"
              prepend-inner-icon="mdi-truck"
            />
          </v-list-item>
          <v-col cols="" class="px-4 py-1">
            <v-btn-toggle>
              <default-date-picker
                label="Data Inicial"
                background-color="background"
                :picker-date.sync="filterParams.dataInicio"
              />
              <default-date-picker
                label="Data Final"
                :picker-date.sync="filterParams.dataFinal"
                background-color="background"
              />
            </v-btn-toggle>
          </v-col>
          <v-list-item class="d-block mt-3">
            <default-filter-button
              @filter="filter"
              @cleanFilter="cleanFilter"
            />
          </v-list-item>
        </v-row>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import DefaultDatePicker from "./DefaultDatePicker.vue";
import DefaultFab from "./DefaultFab.vue";
import DefaultFilterButton from "./DefaultFilterButton.vue";
import DefaultTextField from "./DefaultTextField.vue";
export default {
  name: "DefaultFilterMenu",
  components: {
    DefaultFab,
    DefaultDatePicker,
    DefaultFilterButton,
    DefaultTextField,
  },
  props: {
    hasFilterAll: { type: Boolean, default: false },
    text: {
      type: String,
      required: false,
      default: "Filtros",
    },
    color: {
      type: String,
      required: false,
      default: "primary",
    },
  },
  data() {
    return {
      isMenuOpen: false,
      filterParams: {
        numeroPedido: "",
        transportadoraNome: "",
        dataInicio: "",
        dataFinal: "",
      },
    };
  },
  methods: {
    filter() {
      this.$emit("filter", this.filterParams);
      this.isMenuOpen = false;
    },
    filterAll() {
      this.$emit("filterAll");
      this.isMenuOpen = false;
    },
    cleanFilter() {
      this.$emit("cleanFilter");
      this.setEveryPropToEmptyString(this.filterParams);
    },
    setEveryPropToEmptyString(object) {
      Object.keys(object).forEach((key) => (object[key] = ""));
    },
  },
  computed: {
    initialDate: {
      get() {
        return this.dataInicio;
      },
      set(inputValue) {
        this.$emit("update:dataInicio", inputValue);
      },
    },
    finalDate: {
      get() {
        return this.dataFinal;
      },
      set(inputValue) {
        this.$emit("update:dataFinal", inputValue);
      },
    },
    orderNumber: {
      get() {
        return this.numeroPedido;
      },
      set(inputValue) {
        this.$emit("update:numeroPedido", inputValue ?? "");
      },
    },
    carrierName: {
      get() {
        return this.transportadoraNome;
      },
      set(inputValue) {
        this.$emit("update:transportadoraNome", inputValue ?? "");
      },
    },
  },
};
</script>

<style scoped></style>
