import { HttpRestService } from "../../services/http/HttpRestService";
export default class ConferenciaTriagemRepository {
  static build() {
    return new ConferenciaTriagemRepository();
  }

  getTriagens(urlParams) {
    return HttpRestService.get("/api/v2/triagem/listar", urlParams);
  }
}
