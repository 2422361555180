import ConferenciaTriagemRepository from "../repositories/v2/ConferenciaTriagemRepositoy";
export default class ConferenciaTriagemService {
  vm;
  conferenciaTriagemRepository = ConferenciaTriagemRepository.build();

  constructor(conferenciaTriagemRepository) {
    this.conferenciaTriagemRepository = conferenciaTriagemRepository;
  }

  static build() {
    const conferenciaTriagemRepository = ConferenciaTriagemRepository.build();
    return new ConferenciaTriagemService(conferenciaTriagemRepository);
  }

  setVm(vm) {
    this.vm = vm;
    return this;
  }

  async getTriagens() {
    try {
      const response = await this.conferenciaTriagemRepository.getTriagens(
        this.vm.urlParams
      );
      if (response.status >= 200 && response.status <= 299) {
        this.vm.triagens = response.data.data.list;
        this.vm.pagination = response.data.data.pagination;
        return response;
      }
      this.vm.triagens = [];
      return response;
    } catch (e) {
      console.error({ e });
    }
  }
}
