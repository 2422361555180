<template>
  <div class="py-10">
    <v-icon
      x-large
      class="mr-2"
      color="red"
    >
      mdi-alert-circle-outline
    </v-icon>
    <span class="font-weight-bold text-uppercase text--text"
      >Nenhum resultado encontrado</span
    >
  </div>
</template>

<script>
export default {
  name: "DefaultResultNotFound",
};
</script>

<style scoped></style>
