<template>
  <default-table-body-row class="border my-2">
    <v-col
      cols="12"
      sm="2"
      lg="2"
      class="mb-3 mb-lg-0 mt-4 mt-lg-0"
    >
      <span class="d-block font-weight-bold primary--text">{{
        pedido.numeroPedido
      }}</span>
      <span
        class="text-overline font-weight-light"
        style="font-size: 13px !important"
        >{{ pedido.dataProducao | dateDMY }}</span
      >
    </v-col>
    <v-col
      cols="12"
      sm="3"
      class="mb-3 mb-lg-0 mt-lg-0"
    >
      <span>{{ pedido.transportadoraNome }}</span>
    </v-col>
    <v-col
      cols="12"
      sm="1"
    >
      <span class="">{{ pedido.baiaId }}</span>
    </v-col>
    <v-col
      cols="12"
      sm="2"
      align="center"
    >
      <span class="font-weight-bold primary--text"
        >{{ pedido.qntVolumesTriados + " / " + pedido.qntVolumes }}
      </span>
      <v-icon
        v-if="AreAllVolumesBeeped"
        color="green"
        >mdi-check-bold</v-icon
      >
    </v-col>
    <v-col
      cols="12"
      sm="1"
    >
      <span class="font-weight-bold primary--text d-flex flex-column">
        <v-icon
          v-for="(linhaPendente, index) in pedido.linhasPendentes"
          :key="index"
          size="26"
          color="primary"
          >{{ `mdi-numeric-${linhaPendente}-circle-outline` }}</v-icon
        >
      </span>
    </v-col>
    <v-col
      cols="3"
      class="d-none d-md-block"
    >
      <div class="d-flex flex-column align-items-center">
        <div>
          <span class="d-block font-weight-bold my-2 text-left">
            <v-icon
              small
              class="mr-1"
              :color="
                pedido.pedidoWebStatus == 'CONCLUIDO'
                  ? 'green'
                  : 'yellow darken-2'
              "
              >mdi-circle</v-icon
            >{{ pedido.pedidoWebStatus | formatOrderStatus }}</span
          >
          <span class="d-block font-weight-bold my-2 text-left">
            <v-icon
              small
              class="mr-1"
              :color="
                pedido.baiaStatus == 'TRANSPORTADO'
                  ? 'green'
                  : 'yellow darken-2'
              "
              >mdi-circle</v-icon
            >{{ pedido.baiaStatus | formatBayStatus }}</span
          >
        </div>
      </div>
    </v-col>
  </default-table-body-row>
</template>

<script>
import DefaultTableBodyRow from "@/shared/components/vuetify/DefaultTableBodyRow.vue";
export default {
  name: "ListingRow",
  props: {
    pedido: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  components: {
    DefaultTableBodyRow,
  },
  computed: {
    AreAllVolumesBeeped() {
      return (
        this.pedido.qntVolumesTriados === this.pedido.qntVolumes &&
        this.pedido.qntVolumes > 0
      );
    },
  },
};
</script>

<style scoped></style>
