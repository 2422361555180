<template>
  <v-row align="center" dense class="mb-3">
    <v-col cols="12" sm="8" md="4">
      <v-select
        v-model="selected"
        :items="statuses"
        chips
        clearable
        persistent-placeholder
        placeholder="Selecionar Status"
        multiple
        hide-details
        deletable-chips
        color="primary"
        background-color="base"
        outlined
        dense
        label="Status"
        small-chips
      >
      </v-select>
    </v-col>
    <v-col cols="12" sm="8" md="4">
      <v-select
        v-model="selected"
        :items="bays"
        chips
        dense
        outlined
        label="Baias"
        clearable
        hide-details
        persistent-placeholder
        placeholder="Selecionar Baias"
        multiple
        deletable-chips
        color="primary"
        background-color="base"
        small-chips
      ></v-select>
    </v-col>
    <v-row class="d-none d-md-flex justify-md-center" align="center">
      <v-col md="7" lg="8" xl="8">
        <default-button @click="filterGroup">Filtrar</default-button>
      </v-col>
      <v-col cols="2">
        <default-filter-menu text="Mais Filtros" @filter="filter" />
      </v-col>
      <v-col cols="2" class="d-none d-sm-block">
        <refresh-countdown
          :countdown="countdown"
          :is-refreshing="isRefreshing"
          @click="$emit('refresh')"
          v-if="!isLoading"
        />
      </v-col>
    </v-row>
    <v-col cols="10" sm="3" class="d-block d-md-none">
      <default-button @click="filterGroup">Filtrar</default-button>
    </v-col>
    <v-col class="d-block d-md-none">
      <default-filter-menu text="Mais Filtros" @filter="filter" />
    </v-col>
  </v-row>
</template>

<script>
import DefaultButton from "@/shared/components/vuetify/DefaultButton.vue";
import DefaultFilterMenu from "@/shared/components/vuetify/DefaultFilterMenu.vue";
import RefreshCountdown from "./RefreshCountdown.vue";
export default {
  name: "HeaderComponent",
  components: {
    DefaultButton,
    DefaultFilterMenu,
    RefreshCountdown,
  },
  data() {
    return {
      selected: [],
      statuses: [
        {
          text: "Em Produção",
          value: { statusSep: false },
        },
        {
          text: "Concluídos",
          value: { statusSep: true },
        },
        { text: "Aguardando Palete", value: { statusBaia: false } },
        { text: "Palete na Baia", value: { statusBaia: true } },
      ],
      bays: [
        { text: "1", value: { b1: true } },
        { text: "2", value: { b2: true } },
        { text: "3", value: { b3: true } },
        { text: "4", value: { b4: true } },
        { text: "5", value: { b5: true } },
      ],
    };
  },
  methods: {
    filter(filterParams) {
      this.cleanFilterGroup();
      this.$emit("filter", filterParams);
    },
    filterGroup() {
      const filterParamsObject = this.buildObjectFromArray(
        this.selectedFilterParams
      );
      if (this.moreThanOneOrderStatusSelected) {
        delete filterParamsObject.statusSep;
      }
      if (this.moreThanOneBayStatusSelected) {
        delete filterParamsObject.statusBaia;
      }
      this.$emit("filter", filterParamsObject);
    },
    buildObjectFromArray(array) {
      const object = array.reduce((result, currentObject) => {
        const key = Object.keys(currentObject)[0];
        const value = currentObject[key];
        result[key] = value;
        return result;
      }, {});
      return object;
    },
    cleanFilterGroup() {
      this.selected = [];
    },
  },
  computed: {
    moreThanOneOrderStatusSelected() {
      return (
        this.selectedFilterParams.filter(
          (filterParam) => filterParam.statusSep !== undefined
        ).length > 1
      );
    },
    moreThanOneBayStatusSelected() {
      return (
        this.selectedFilterParams.filter(
          (filterParam) => filterParam.statusBaia !== undefined
        ).length > 1
      );
    },
    selectedFilterParams() {
      return JSON.parse(JSON.stringify(this.selected));
    },
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    isRefreshing: {
      type: Boolean,
      default: false,
    },
    countdown: {
      type: Number,
      required: true,
      default: 0,
    },
  },
};
</script>

<style scoped></style>
