<template>
  <default-fab
    :top="false"
    :left="true"
    v-on="$listeners"
    v-bind="$attrs"
    :tooltip-text="isRefreshing ? 'Atualizando' : 'Atualizar'"
    color="green darken-1"
  >
    <span v-if="!isRefreshing" class="white--text h6 font-weight-bolder">{{
      countdown / 1000
    }}</span>
    <v-icon v-else size="40" color="white">{{
      `mdi-cached ${isRefreshing ? "mdi-spin" : ""}`
    }}</v-icon>
  </default-fab>
</template>

<script>
import DefaultFab from "@/shared/components/vuetify/DefaultFab.vue";
export default {
  name: "RefreshCountdown",
  components: {
    DefaultFab,
  },
  props: {
    isRefreshing: {
      type: Boolean,
      default: false,
    },
    countdown: {
      type: Number,
      required: true,
      default: 0,
    },
  },
};
</script>

<style scoped></style>
