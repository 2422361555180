<template>
  <div>
    <div
      class="d-flex d-md-none card-container mx-auto"
      style="max-width: 1440px"
    >
      <listing-card
        v-for="(pedido, index) in pedidos"
        :key="index"
        :pedido="pedido"
      />
      <div
        v-for="index in maxCardsPerRow"
        :key="index + 'B'"
        class="filling-empty-space-childs"
      ></div>
    </div>
    <div class="d-none d-md-block">
      <default-table-head>
        <b-col cols="2" class="border-right py-2">Pedido</b-col>
        <b-col cols="3" class="border-right py-2 text-truncate"
          >Transportadora</b-col
        >
        <b-col cols="1" class="py-2 border-right">Baia</b-col>
        <b-col cols="2" class="py-2 border-right text-truncate"
          >Volumes Bipados</b-col
        >
        <b-col cols="1" class="py-2 border-right">Linhas</b-col>
        <b-col cols="3" class="py-2">Status</b-col>
      </default-table-head>
      <listing-row
        v-for="(pedido, index) in pedidos"
        :key="index"
        :pedido="pedido"
      />
    </div>
  </div>
</template>

<script>
import DefaultTableHead from "@/shared/components/vuetify/DefaultTableHead.vue";
import ListingCard from "./ListingCard.vue";
import ListingRow from "./ListingRow.vue";
export default {
  name: "ListingComponent",
  data() {
    return {
      maxCardsPerRow: 4,
    };
  },
  props: {
    pedidos: {
      type: Array,
      required: true,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
    pagination: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  components: {
    DefaultTableHead,
    ListingCard,
    ListingRow,
  },
};
</script>

<style scoped>
.card-container {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.filling-empty-space-childs {
  width: 220px;
  height: 0;
}
</style>
