<template>
  <v-card
    class="rounded-sm"
    width="220"
    height="100%"
  >
    <v-system-bar
      :color="pedido.baiaStatus === 'TRANSPORTADO' ? '#b8e791' : '#faf882'"
      height="30"
      ><v-spacer></v-spacer
      ><span class="font-weight-bold black--text overline">{{
        pedido.pedidoWebStatus === "PRODUZINDO" ? "EM PRODUÇÃO" : "CONCLUÍDO"
      }}</span
      ><v-spacer></v-spacer
    ></v-system-bar>
    <default-table-body-row
      style="margin: 0 !important"
      justify="start"
      align="start"
      class="card rounded-t-0 rounded-b-sm"
    >
      <v-col
        class="pa-0 ma-0 pt-1 text-end"
        style="height: 30px"
        ><span class="overline">Baia</span>
        <v-icon
          size="35"
          color="primary"
          class="ml-n2 pr-0"
          >{{ `mdi-numeric-${pedido.baiaId}` }}</v-icon
        ></v-col
      >
      <v-col
        cols="12"
        class="py-1"
      >
        <span class="primary--text font-weight-bold"
          ><default-icon
            icon="mdi-package-variant-closed"
            tooltip-text="Pedido"
          />
          <span
            class="ml-2"
            :title="pedido.numeroPedido"
            >{{ pedido.numeroPedido }}</span
          ></span
        >
      </v-col>
      <v-col
        cols="12"
        class="py-1"
      >
        <div
          class="text-truncate"
          :title="pedido.transportadoraNome"
        >
          <default-icon
            icon="mdi-truck"
            tooltip-text="Transportadora"
          />
          <span class="ml-2">{{ pedido.transportadoraNome }}</span>
        </div></v-col
      >
      <v-col
        cols="12"
        class="py-1"
      >
        <span :title="pedido.dataProducao | dateDMY">
          <default-icon
            icon="mdi-calendar-clock"
            tooltip-text="Data e Hora"
          />
          <span class="ml-2">{{ pedido.dataProducao | dateDMY }}</span>
        </span></v-col
      >
      <v-col
        cols="12"
        class="py-1"
      >
        <default-icon
          icon="mdi-barcode"
          tooltip-text="Volumes Bipados"
        />
        <span class="ml-2">
          {{ pedido.qntVolumesTriados + " / " + pedido.qntVolumes }}
        </span>
        <v-icon
          v-if="AreAllVolumesBeeped"
          color="green"
          >mdi-check-bold</v-icon
        ></v-col
      >
      <v-col
        cols="12"
        class="py-1"
      >
        <default-icon
          icon="mdi-format-list-numbered"
          tooltip-text="Linhas Pendentes"
        />
        <span class="ml-2">
          <v-icon
            v-for="(linhaPendente, index) in pedido.linhasPendentes"
            :key="index"
            size="26"
            color="primary"
            >{{ `mdi-numeric-${linhaPendente}-circle-outline` }}</v-icon
          >
        </span>
      </v-col>
    </default-table-body-row>
  </v-card>
</template>

<script>
import DefaultIcon from "@/shared/components/vuetify/DefaultIcon.vue";
import DefaultTableBodyRow from "../../../shared/components/vuetify/DefaultTableBodyRow.vue";
export default {
  components: {
    DefaultIcon,
    DefaultTableBodyRow,
  },
  name: "ListingCard",
  data() {
    return {};
  },
  props: {
    pedido: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  computed: {
    AreAllVolumesBeeped() {
      return (
        this.pedido.qntVolumesTriados === this.pedido.qntVolumes &&
        this.pedido.qntVolumes > 0
      );
    },
  },
};
</script>

<style scoped></style>
