<template>
  <v-container fluid>
    <header-component
      v-intersect="onIntersectingHeader"
      :is-loading="isLoading"
      :countdown="countdown"
      :is-refreshing="isRefreshing"
      @filter="filter"
      @refresh="refresh"
    />
    <template v-if="!isLoading">
      <listing-component
        :pedidos="triagens"
        :is-loading="isLoading"
        :pagination="pagination"
        @filter="filter"
        @paginate="paginate"
      />
      <default-pagination
        v-if="triagens.length > 0"
        v-model="pagination.currentPage"
        :length="pagination.lastPage"
        @input="paginate(pagination.currentPage)"
        :disabled="pagination.length === 0"
      />
      <default-result-not-found v-else />
    </template>
    <default-spinner v-else />
    <div
      v-if="!isLoading"
      class="d-flex flex-column d-md-none"
      style="gap: 5px; position: fixed; bottom: 12px; right: 16px"
    >
      <span v-show="!isHeaderVisible">
        <back-to-top @click="scrollToTop" />
      </span>
      <refresh-countdown
        :countdown="countdown"
        :is-refreshing="isRefreshing"
        class="d-inline-block"
        @click="refresh"
      />
    </div>
  </v-container>
</template>

<script>
import BackToTop from "@/shared/components/vuetify/BackToTop.vue";
import ConferenciaTriagemService from "../../../services/ConferenciaTriagemService";
import DefaultPagination from "@/shared/components/vuetify/DefaultPagination.vue";
import DefaultSpinner from "@/shared/components/vuetify/DefaultSpinner.vue";
import DefaultResultNotFound from "@/shared/components/vuetify/DefaultResultNotFound.vue";
import HeaderComponent from "../components/HeaderComponent.vue";
import ListingComponent from "../components/ListingComponent.vue";
import RefreshCountdown from "../components/RefreshCountdown.vue";
export default {
  name: "ConferenciaTriagemView",
  components: {
    BackToTop,
    DefaultPagination,
    DefaultSpinner,
    DefaultResultNotFound,
    HeaderComponent,
    ListingComponent,
    RefreshCountdown,
  },
  data() {
    return {
      conferenciaTriagemService: ConferenciaTriagemService.build(),
      requestDelay: 10000,
      debouncingDelay: 1000,
      countdown: 0,
      isHeaderVisible: true,
      triagens: [],
      isLoading: false,
      isRefreshing: false,
      pagination: {
        total: 0,
        perPage: 0,
        currentPage: 1,
        lastPage: 0,
        previousPage: 0,
        nextPage: 0,
      },
      statusFlowHack: "",
      requestIntervalID: "",
      countdownIntervalID: "",
      timeoutID: "",
      urlParams: {
        page: 1,
        perPage: 18,
        pedidoStatusId: "",
        numeroPedido: "",
        transportadoraNome: "",
        dataInicio: "",
        dataFinal: "",
        b1: "",
        b2: "",
        b3: "",
        b4: "",
        b5: "",
        statusBaia: "",
        statusSep: "",
      },
    };
  },
  async created() {
    this.conferenciaTriagemService =
      ConferenciaTriagemService.build().setVm(this);
    this.conferenciaTriagemService.setVm(this);
    await this.executeServiceMethod(this.getTriagens);
  },
  beforeDestroy() {
    this.cancelPreviousInterval(this.requestIntervalID);
    this.cancelPreviousInterval(this.countdownIntervalID);
  },
  methods: {
    async refresh() {
      if (this.isLoading) {
        return;
      }
      await this.debounce(this.getTriagens, this.debouncingDelay, false);
    },
    onIntersectingHeader(entries) {
      this.isHeaderVisible = entries[0].isIntersecting;
    },
    scrollToTop() {
      return this.$vuetify.goTo(0);
    },
    async getTriagens() {
      await this.conferenciaTriagemService.getTriagens();
    },
    async filter(filterParams) {
      this.resetUrlParams();
      this.setFilterParamsIntoUrlParams(filterParams);
      this.executeServiceMethod(() =>
        this.conferenciaTriagemService.getTriagens()
      );
    },
    async paginate(page) {
      this.urlParams.page = page;
      this.executeServiceMethod(() =>
        this.conferenciaTriagemService.getTriagens()
      );
    },
    async executeServiceMethod(serviceMethod) {
      await this.debounce(serviceMethod, 1000);
      this.cancelPreviousInterval(this.requestIntervalID);
      this.requestIntervalID = setInterval(async () => {
        this.isRefreshing = true;
        await serviceMethod();
        this.isRefreshing = false;
        this.setCountdown();
      }, this.requestDelay);
    },
    cancelPreviousInterval(intervalID) {
      clearInterval(intervalID);
    },
    cancelPreviousTimeout(timeoutID) {
      clearTimeout(timeoutID);
    },
    resetUrlParams() {
      this.urlParams.page = 1;
      this.urlParams.perPage = 18;
      this.urlParams.pedidoStatusId = "";
      this.urlParams.numeroPedido = "";
      this.urlParams.transportadoraNome = "";
      this.urlParams.dataInicio = "";
      this.urlParams.dataFinal = "";
      this.urlParams.b1 = "";
      this.urlParams.b2 = "";
      this.urlParams.b3 = "";
      this.urlParams.b4 = "";
      this.urlParams.b5 = "";
      this.urlParams.statusBaia = "";
      this.urlParams.statusSep = "";
    },
    setFilterParamsIntoUrlParams(filterParams) {
      this.urlParams = { ...this.urlParams, ...filterParams };
    },
    debounce(request, delay, enableLoading = true) {
      if (enableLoading) {
        this.isLoading = true;
      }
      this.isRefreshing = true;
      this.cancelPreviousTimeout(this.timeoutID);
      this.timeoutID = setTimeout(async () => {
        await request();
        if (enableLoading) {
          this.isLoading = false;
        }
        this.isRefreshing = false;
      }, delay);
      this.setCountdown();
    },
    setCountdown() {
      this.cancelPreviousInterval(this.countdownIntervalID);
      this.countdown = this.requestDelay;
      this.countdownIntervalID = setInterval(() => {
        this.countdown -= 1000;
      }, 1000);
    },
  },
};
</script>

<style scoped></style>
